import { mount } from 'svelte'
import './app.css'
import App from './App.svelte'

const targetElement = document.getElementById('svelte-app');
let app;

if (targetElement) {
  app = mount(App, {
    target: targetElement,
  });
} else {
  // console.error('Target element not found: #svelte-app');
}

export default app;