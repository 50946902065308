<script lang="ts">
  import { onMount } from "svelte";
  import {
    _,
    init,
    register,
    getLocaleFromNavigator,
    waitLocale,
  } from "svelte-i18n";

  register("en", () => import("./locales/en.json"));
  register("sv", () => import("./locales/sv.json"));

  // TODO : Check how WordPress sets the locale
  const initPromise = init({
    fallbackLocale: "sv",
    initialLocale: "sv",
  });

  // General
  let debug: boolean = false;
  let errorMessage: string = $state("");
  let step: number = $state(0);
  let loading: boolean = $state(false);
  let localesLoaded: boolean = $state(false);

  // Key value pairs
  let positions: { key: string; value: string }[] = $state([]);

  $effect(() => {
    if (errorMessage !== "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: adds a smooth scrolling effect
      });
    }
  });

  // Step 0
  let email: string = $state("");

  // Step 1
  let orgNumber: string = $state("");
  interface ExistingCompany {
    companyName: string;
    pyramidId: string;
    companyInfo: string;
  }
  let existingCompanies: ExistingCompany[] = $state([]);

  // Step 2
  let selectedCompany: "new" | ExistingCompany | false = $state(false); // new or existing ID
  let pyramidId: string | false = $state(false);

  // Step 3
  let companyName: string = $state("");
  let address: string = $state("");
  let postCode: string = $state("");
  let area: string = $state("");
  let deliveryAddress: string = $state("");
  let deliveryPostCode: string = $state("");
  let deliveryArea: string = $state("");
  let extraAddress: string = $state("");
  let country: string = $state("");
  let invoiceType: false | "INVOICM" | "INVOICIX" = $state("INVOICM");
  let invoiceEmail: string = $state("");

  // Step 4
  let firstName: string = $state("");
  let lastName: string = $state("");
  let userPhone: string = $state("");
  let position: string = $state("");
  let password: string = $state("");
  let confirmPassword: string = $state("");

  // Derived state for button label
  const buttonLabel = $derived.by(() => {
    if (loading) {
      return "...";
    }

    if (step === 0 || step === 1 || step === 2 || step === 3) {
      return $_("continue");
    }

    return $_("submit");
  });

  onMount(async () => {
    waitLocale().then(() => {
      localesLoaded = true;
    });

    try {
      const response = await fetch(
        "/wp-json/collinder-register/v1/get-positions",
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        return; // Exit the function or handle the error as needed
      }

      const responseData = await response.json();
      const unsortedPositions = Array.from(responseData.positions) as {
        key: string;
        value: string;
      }[];

      positions = unsortedPositions.sort((a, b) => {
        return parseInt(a.key) - parseInt(b.key);
      });
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Network Error:", error);
      errorMessage = $_("genericError");
    }
  });

  async function checkEmail() {
    try {
      const response = await fetch(
        "/wp-json/collinder-register/v1/check-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        // Handle HTTP error status codes
        const errorData = await response.json();
        console.log(errorData.messageCode);
        errorMessage = $_(errorData.messageCode);
        return; // Exit the function or handle the error as needed
      }

      step = 1;
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Network Error:", error);
      errorMessage = $_("genericError");
    }
  }

  async function checkOrgNumber() {
    try {
      const response = await fetch(
        "/wp-json/collinder-register/v1/check-org-number",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ orgNumber }),
        }
      );

      if (!response.ok) {
        // Handle HTTP error status codes
        const errorData = await response.json();

        console.log(errorData.messageCode);

        errorMessage = $_(errorData.messageCode);
        return; // Exit the function or handle the error as needed
      }

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.foundCompanies.length > 0) {
        existingCompanies = responseData.foundCompanies;
        step = 2;
      } else {
        step = 3;
      }
    } catch (error) {
      console.error("Network Error:", error);
      errorMessage = $_("genericError");
    }
  }

  async function registerCompany() {
    try {
      const response = await fetch(
        "/wp-json/collinder-register/v1/register-company",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orgNumber,
            companyName,
            address,
            postCode,
            area,
            deliveryAddress,
            deliveryPostCode,
            deliveryArea,
            extraAddress,
            country,
            invoiceType,
            invoiceEmail,
          }),
        }
      );

      if (!response.ok) {
        // Handle HTTP error status codes
        const errorData = await response.json();
        console.log(errorData.messageCode);
        errorMessage = $_(errorData.messageCode);
        return; // Exit the function or handle the error as needed
      }

      const responseData = await response.json();

      pyramidId = responseData.pyramidId;

      step = 4;
    } catch (error) {
      console.error("Network Error:", error);
      errorMessage = $_("genericError");
    }
  }

  async function registerUser() {
    try {
      const response = await fetch(
        "/wp-json/collinder-register/v1/register-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pyramidId,
            email,
            orgNumber,
            firstName,
            lastName,
            userPhone,
            position,
            password,
            confirmPassword,
          }),
        }
      );

      if (!response.ok) {
        // Handle HTTP error status codes
        const errorData = await response.json();
        console.log(errorData.messageCode);
        errorMessage = $_(errorData.messageCode);
        return; // Exit the function or handle the error as needed
      }

      const responseData = await response.json();
      console.log(responseData);

      // Redirect to /mina-sidor
      window.location.href = "/mina-sidor";
    } catch (error) {
      console.error("Network Error:", error);
      errorMessage = $_("genericError");
    }
  }

  function checkSelectedCompany() {
    if (!selectedCompany) {
      errorMessage = $_("selectCompanyError");
      return;
    }

    if (selectedCompany === "new") {
      step = 3;
    } else {
      step = 4;
    }
  }

  function handleBack() {
    if (loading) return;

    console.log(selectedCompany);

    errorMessage = "";
    if (step === 1) {
      email = "";
    } else if (step === 2) {
      orgNumber = "";
      existingCompanies = [];
    } else if (step === 3) {
      if (existingCompanies.length === 0) {
        step -= 1;
      }

      selectedCompany = false;
      companyName = "";
      address = "";
      postCode = "";
      area = "";
      deliveryAddress = "";
      deliveryPostCode = "";
      deliveryArea = "";
      extraAddress = "";
      country = "";
      invoiceType = false;
      invoiceEmail = "";
    } else if (step === 4) {
      if (selectedCompany !== "new" && selectedCompany !== false) {
        selectedCompany = false;
        companyName = "";
        address = "";
        postCode = "";
        area = "";
        deliveryAddress = "";
        deliveryPostCode = "";
        deliveryArea = "";
        extraAddress = "";
        country = "";
        invoiceType = false;
        invoiceEmail = "";
        step -= 1;
      }

      firstName = "";
      lastName = "";
      userPhone = "";
      position = "";
      password = "";
      confirmPassword = "";
    }

    step -= 1;

    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds a smooth scrolling effect
    });
  }

  async function handleButton() {
    if (loading) return;

    loading = true;
    errorMessage = "";

    if (step === 0) {
      await checkEmail();
    } else if (step === 1) {
      await checkOrgNumber();
    } else if (step === 2) {
      checkSelectedCompany();
    } else if (step === 3) {
      await registerCompany();
    } else if (step === 4) {
      await registerUser();
    }

    loading = false;
  }

  function handleOrgNumberInput(event: Event) {
    const input = event.target as HTMLInputElement;
    // Remove non-numeric characters
    let value = input.value.replace(/\D/g, "");
    // Limit to 10 characters
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    // Update the input value and orgNumber
    input.value = value;
    orgNumber = value;
  }

  function handleOrgNumberPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      let pastedData = clipboardData.getData("text");
      // Remove non-numeric characters
      let value = pastedData.replace(/\D/g, "");
      // Limit to 10 characters
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      // Update the input value and orgNumber
      orgNumber = value;
    }
  }
</script>

{#if localesLoaded}
  <div
    class="collinder-register-component wrap-xs flex flex-col !mb-12 !mt-6 min-h-[400px]"
  >
    <div class="flex flex-col gap-5">
      {#if errorMessage}
        <div
          class="collinder-register-error-message !border-2 !border-[#ef7d00] text-[#ef7d00] bg-white !px-4 !py-2 rounded-xl font-bold"
        >
          {@html errorMessage}
        </div>
      {/if}

      {#if step === 0}
        <div class="flex flex-col">
          <label for="email">{$_("emailLabel")}</label>
          <input
            id="email"
            type="email"
            placeholder={$_("emailPlaceholder")}
            bind:value={email}
          />
        </div>
      {/if}

      {#if step === 1}
        <div class="flex flex-col">
          <label for="org-number">{$_("orgNumberLabel")}</label>
          <input
            id="org-number"
            type="text"
            placeholder={$_("orgNumberPlaceholder")}
            bind:value={orgNumber}
            oninput={handleOrgNumberInput}
            onpaste={handleOrgNumberPaste}
          />
        </div>
      {/if}

      {#if step === 2}
        <div class="flex flex-col">
          <span class="label">{$_("selectSectionLabel")}</span>

          <div
            class="flex flex-col bg-white rounded-xl !border-2 !border-[#005984] overflow-hidden"
          >
            {#each existingCompanies as company}
              <button
                class="flex flex-col gap-1 !items-start not-visited:!rounded-none !px-4 !border-r-0 !border-l-0 !border-t-0 last:!border-b-0 !border-b !border-[#005984] !text-[#005984]
                  {selectedCompany === company
                  ? '!bg-[#d2dce9] hover:!bg-[#c6d0dd]'
                  : '!bg-transparent hover:!bg-[#f0f0f0]'}
                "
                onclick={() => {
                  if (selectedCompany === company) {
                    selectedCompany = false;
                    pyramidId = false;
                  } else {
                    selectedCompany = company;
                    pyramidId = company.pyramidId;
                  }
                }}
              >
                <span>{company.companyName}</span>
                {#if company.companyInfo !== ""}
                  <span class="font-medium text-[14px]"
                    >{company.companyInfo}</span
                  >
                {/if}
              </button>
            {/each}
          </div>
        </div>
      {/if}

      {#if step === 3}
        <h4 class="!mb-0 !text-[#666666]">Företagsinfo</h4>
        <div class="flex flex-col">
          <label for="company-name">{$_("companyNameLabel")}</label>
          <input
            id="company-name"
            type="text"
            placeholder={$_("companyNamePlaceholder")}
            bind:value={companyName}
          />
        </div>
        <div class="flex flex-col">
          <label for="address">{$_("addressLabel")}</label>
          <input
            id="address"
            type="text"
            placeholder={$_("addressPlaceholder")}
            bind:value={address}
          />
        </div>

        <div class="flex flex-col">
          <label for="post-code">{$_("postCodeLabel")}</label>
          <input
            id="post-code"
            type="text"
            placeholder={$_("postCodePlaceholder")}
            bind:value={postCode}
          />
        </div>

        <div class="flex flex-col">
          <label for="area">{$_("areaLabel")}</label>
          <input
            id="area"
            type="text"
            placeholder={$_("areaPlaceholder")}
            bind:value={area}
          />
        </div>

        <div class="flex flex-col">
          <label for="country">{$_("countryLabel")}</label>
          <input
            id="country"
            type="text"
            placeholder={$_("countryPlaceholder")}
            bind:value={country}
          />
        </div>
        <!-- 
        <div class="flex flex-col">
          <label for="invoice-type">{$_("invoiceTypeLabel")}</label>
          <select
            id="invoice-type"
            bind:value={invoiceType}
            class={invoiceType === false ? "!text-[#568eab] !text-[14px]" : ""}
          >
            <option value={false} disabled
              >{$_("invoiceTypePlaceholder")}</option
            >
            <option value="INVOICM">{$_("invoiceEmailType")}</option>
            <option value="INVOICIX">{$_("invoideEDIType")}</option>
          </select>
        </div> -->
        {#if invoiceType === "INVOICM"}
          <div class="flex flex-col">
            <label for="invoice-email">{$_("invoiceEmailLabel")}</label>
            <input
              id="invoice-email"
              type="email"
              placeholder={$_("invoiceEmailPlaceholder")}
              bind:value={invoiceEmail}
            />
          </div>
        {/if}

        <h4 class="!mt-6 !mb-0 !text-[#666666]">Leveransinfo</h4>

        <div class="flex flex-col">
          <label for="delivery-address">{$_("deliveryAddressLabel")}</label>
          <input
            id="delivery-address"
            type="text"
            placeholder={$_("deliveryAddressPlaceholder")}
            bind:value={deliveryAddress}
          />
        </div>

        <div class="flex flex-col">
          <label for="delivery-post-code">{$_("deliveryPostCodeLabel")}</label>
          <input
            id="delivery-post-code"
            type="text"
            placeholder={$_("deliveryPostCodePlaceholder")}
            bind:value={deliveryPostCode}
          />
        </div>

        <div class="flex flex-col">
          <label for="delivery-area">{$_("deliveryAreaLabel")}</label>
          <input
            id="delivery-area"
            type="text"
            placeholder={$_("deliveryAreaPlaceholder")}
            bind:value={deliveryArea}
          />
        </div>

        <div class="flex flex-col">
          <label for="extra-address">{$_("extraAddressLabel")}</label>
          <input
            id="extra-address"
            type="text"
            placeholder={$_("extraAddressPlaceholder")}
            bind:value={extraAddress}
          />
        </div>
      {/if}

      {#if step === 4}
        <div class="flex flex-col">
          <label for="first-name">{$_("firstNameLabel")}</label>
          <input
            id="first-name"
            type="text"
            placeholder={$_("firstNamePlaceholder")}
            bind:value={firstName}
          />
        </div>
        <div class="flex flex-col">
          <label for="last-name">{$_("lastNameLabel")}</label>
          <input
            id="last-name"
            type="text"
            placeholder={$_("lastNamePlaceholder")}
            bind:value={lastName}
          />
        </div>
        <div class="flex flex-col">
          <label for="user-phone">{$_("userPhoneLabel")}</label>
          <input
            id="user-phone"
            type="text"
            placeholder={$_("userPhonePlaceholder")}
            bind:value={userPhone}
          />
        </div>
        <div class="flex flex-col">
          <label for="position">{$_("positionLabel")}</label>
          <select
            id="position"
            class={position === "" ? "!text-[#568eab] !text-[14px]" : ""}
            bind:value={position}
          >
            <option value="" disabled>{$_("positionPlaceholder")}</option>
            {#each positions as pos}
              <option value={pos.key}>{pos.value}</option>
            {/each}
          </select>
        </div>
        <div class="flex flex-col">
          <label for="password">{$_("passwordLabel")}</label>
          <input
            id="password"
            type="password"
            placeholder={$_("passwordPlaceholder")}
            bind:value={password}
          />
        </div>
        <div class="flex flex-col">
          <label for="confirm-password">{$_("confirmPasswordLabel")}</label>
          <input
            id="confirm-password"
            type="password"
            placeholder={$_("confirmPasswordPlaceholder")}
            bind:value={confirmPassword}
          />
        </div>
      {/if}

      <div class="flex justify-between">
        {#if step > 0}
          <button
            class="!bg-transparent !px-0 !text-[#0c3041] opacity-70 !border-0 hover:opacity-100"
            onclick={handleBack}>{$_("goBack")}</button
          >
        {/if}

        <button class="!ml-auto" onclick={handleButton}>{buttonLabel}</button>
      </div>
    </div>

    {#if debug}
      <div class="bg-gray-300 rounded-lg !text-[12px] !mb-4 !mt-4 !p-4">
        <pre>{JSON.stringify(
            {
              step,
              pyramidId,
              email,
              orgNumber,
              existingCompanies,
              selectedCompany,
              companyName,
              address,
              postCode,
              area,
              deliveryAddress,
              deliveryPostCode,
              deliveryArea,
              extraAddress,
              country,
              invoiceType,
              invoiceEmail,
              firstName,
              lastName,
              userPhone,
              position,
              password,
              confirmPassword,
              positions,
            },
            null,
            2
          )}</pre>
      </div>
    {/if}
  </div>
{/if}

<style scoped>
  .collinder-register-component label,
  .collinder-register-component .label {
    color: #666c75;
    display: block;
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    padding-left: 20px;
  }

  /* Placeholder opacity */
  .collinder-register-component input::placeholder {
    opacity: 0.7;
  }
</style>
